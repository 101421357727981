import { AfterContentInit, Component, ContentChild, Input } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ClusivityType } from '../../_models/models';
import { ClusivityList, createEmptyClusivityList } from '../../_models/clusivity-list';
import { isEmpty } from 'lodash';
import { ClusivityListItemsComponent } from './clusivity-list-items.component';
import { FormPartComponent } from '../form-part-component';

type FormPartType<TItem> = FormGroup<{
  clusivity: FormControl<ClusivityType>;
  items: FormControl<TItem[]>;
}>;

@Component({
  selector: 'app-clusivity-list',
  templateUrl: './clusivity-list.component.html',
  styleUrls: ['./clusivity-list.component.less'],
  providers: FormPartComponent.createDirectiveProviders(ClusivityListComponent)
})
export class ClusivityListComponent<TItem>
  extends FormPartComponent<ClusivityList<TItem>, FormPartType<TItem>>
  implements AfterContentInit {

  @Input({ required: true }) label!: string;
  @Input() radioGroupElementId?: string;
  @Input() errorMessage?: string;

  @ContentChild(ClusivityListItemsComponent)
  private itemsComponent: ClusivityListItemsComponent<TItem>;

  private readonly _formPart = this.createFormPart();

  constructor() {
    super();
    this.subscribeToChanges();
  }

  protected override get formPart(): FormPartType<TItem> {
    return this._formPart;
  }

  protected get areNoItemsSelected(): boolean {
    const selectedItems = this.formPart.controls.items.value;
    return isEmpty(selectedItems);
  }

  ngAfterContentInit(): void {
    this.validateItemsComponentExists();
    this.initItemsComponentWiring();
  }

  override writeValue(newList: ClusivityList<TItem>): void {
    super.writeValue(newList);

    if (this.itemsComponent) {
      this.setItemsIntoComponent();
    }
  }

  override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);

    if (this.itemsComponent) {
      this.setItemsComponentDisabledState(isDisabled);
    }
  }

  protected override getFormPartValidationErrors(): ValidationErrors {
    return { clusivityList: 'clusivity list is invalid.' };
  }

  private createFormPart(): FormPartType<TItem> {
    const emptyClusivityList = createEmptyClusivityList<TItem>();

    return new FormGroup({
      clusivity: new FormControl(emptyClusivityList.clusivity),
      items: new FormControl(emptyClusivityList.items)
    });
  }

  private subscribeToChanges(): void {
    const internalChangesStream = this.createChangesStream();
    const subscription = internalChangesStream.subscribe(newList => this.onChanged(newList));
    this.subscriptions.add(subscription);
  }

  private onChanged(newList: ClusivityList<TItem>): void {
    const hasItems = !isEmpty(newList.items);
    const hasClusivity = newList.clusivity !== null;
    const clusivityFormControl = this.formPart.controls.clusivity;

    if (hasItems && !hasClusivity) {
      clusivityFormControl.setValue('include');
    } else if (!hasItems && hasClusivity) {
      clusivityFormControl.setValue(null);
    }
  }

  private initItemsComponentWiring(): void {
    this.setItemsIntoComponent();
    this.subscribeToComponentItemChanges();
    this.subscribeToComponentOnTouched();
  }

  private validateItemsComponentExists(): void {
    if (!this.itemsComponent) {
      throw new Error('ClusivityListComponent requires a ClusivityListItemsComponent to be provided as content child.');
    }
  }

  private setItemsIntoComponent(): void {
    const itemsFormControl = this.formPart.controls.items;
    this.itemsComponent.items = itemsFormControl.value;
  }

  private subscribeToComponentItemChanges(): void {
    const itemsFormControl = this.formPart.controls.items;
    const subscription = this.itemsComponent.items.subscribe(items => itemsFormControl.setValue(items));
    this.subscriptions.add(subscription);
  }

  private subscribeToComponentOnTouched(): void {
    const onTouchedStream = this.itemsComponent.onTouchedStream;
    const subscription = onTouchedStream.subscribe(() => this.formPart.markAsTouched());
    this.subscriptions.add(subscription);
  }

  private setItemsComponentDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.itemsComponent.disable();
    } else {
      this.itemsComponent.enable();
    }
  }
}
