<nz-row nzGutter="16" [formGroup]="taxForm">

  <nz-col nzSm="24" nzMd="12" nzLg="6">
    <nz-form-item
      [ngClass]="{'has-error': taxForm.get('legalCompanyName')?.touched && taxForm.get('legalCompanyName')?.errors}">
      <nz-form-label [nzRequired]="isRequired(taxForm, 'legalCompanyName')">Beneficiary Name</nz-form-label>
      <nz-form-control [nzErrorTip]="getError('legalCompanyName', 'Beneficiary required')">
        <input nz-input
               formControlName="legalCompanyName"
               id="payment_form_legal_company_name"
               placeholder="Enter a beneficiary's name"
               class="readonly-field">
      </nz-form-control>
    </nz-form-item>
  </nz-col>

  <nz-col nzSm="24" nzMd="12" nzLg="6">
    <nz-form-item [ngClass]="{'has-error': taxForm.get('country')?.touched && taxForm.get('country')?.errors}">
      <nz-form-label [nzRequired]="isRequired(taxForm, 'country')">Country</nz-form-label>
      <nz-form-control [nzErrorTip]="getError('country', 'Country required')">
        <nz-select formControlName="country" id="payment_form_country"
                   nzAllowClear nzPlaceHolder="Select a country" nzShowSearch
                   nzShowArrow="false"
                   [nzSuffixIcon]="selectIcons['country'] ? arrowUp : arrowDown"
                   (nzOpenChange)="onChangeSelectIconState($event, 'country')"
                   class="readonly-field">
          <nz-option *ngFor="let country of countriesOptions" [nzValue]="country.alpha2"
                     [nzLabel]="country.name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </nz-col>

  <nz-col nzSm="24" nzMd="12" nzLg="6">
    <nz-form-item [ngClass]="{'has-error': taxForm.get('address1')?.touched && taxForm.get('address1')?.errors}">
      <nz-form-label [nzRequired]="isRequired(taxForm, 'address1')">Address Line 1</nz-form-label>
      <nz-form-control [nzErrorTip]="getError('address1', 'Address required')">
        <input nz-input
               formControlName="address1"
               id="payment_form_address_1"
               placeholder="Enter an address"
               autocomplete="off"
               class="readonly-field">
      </nz-form-control>
    </nz-form-item>
  </nz-col>

  <nz-col nzSm="24" nzMd="12" nzLg="6">
    <nz-form-item>
      <nz-form-label>Address Line 2</nz-form-label>
      <nz-form-control>
        <input nz-input
               formControlName="address2"
               id="payment_form_address_2"
               placeholder="Enter a secondary address"
               autocomplete="off"
               class="readonly-field">
      </nz-form-control>
    </nz-form-item>
  </nz-col>

  <nz-col nzSm="24" nzMd="12" nzLg="6">
    <nz-form-item [ngClass]="{'has-error': taxForm.get('city')?.touched && taxForm.get('city')?.errors}">
      <nz-form-label [nzRequired]="isRequired(taxForm, 'city')">City</nz-form-label>
      <nz-form-control [nzErrorTip]="getError('city', 'City required')">
        <input nz-input
               formControlName="city"
               id="payment_form_city"
               placeholder="Enter a city"
               autocomplete="off"
               class="readonly-field">
      </nz-form-control>
    </nz-form-item>
  </nz-col>

  <nz-col nzSm="24" nzMd="12" nzLg="6">
    <nz-form-item [ngClass]="{'has-error': taxForm.get('state')?.touched && taxForm.get('state')?.errors}">
      <nz-form-label [nzRequired]="taxForm.get('country').value === 'US' && isRequired(taxForm, 'country')">
        State/Province/Region
      </nz-form-label>
      <nz-form-control [nzErrorTip]="getError('state', 'Please select a state')">
        <nz-select nzShowSearch nzAllowClear #stateSelector
                   nzShowArrow="false"
                   [nzSuffixIcon]="selectIcons['state'] ? arrowUp : arrowDown"
                   (nzOpenChange)="onChangeSelectIconState($event, 'state')"
                   formControlName="state"
                   [nzSize]="'width: 100%'"
                   nzPlaceHolder="Select a state/province/region"
                   [nzLoading]="loadingStates"
                   class="readonly-field">
          <nz-option *ngFor="let state of statesList" [nzLabel]="state.displayRegion | titlecase" [nzValue]="state.region">
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </nz-col>

  <nz-col nzSm="24" nzMd="12" nzLg="6">
    <nz-form-item [ngClass]="{'has-error': taxForm.get('zipCode')?.touched && taxForm.get('zipCode')?.errors}">
      <nz-form-label [nzRequired]="isRequired(taxForm, 'zipCode')">Zip Code</nz-form-label>
      <nz-form-control [nzErrorTip]="zipCodeErrorTipTpl">
        <input nz-input
               formControlName="zipCode"
               id="payment_form_zip_code"
               placeholder="Enter a zip code"
               autocomplete="off"
               class="readonly-field">
      </nz-form-control>
      <ng-template #zipCodeErrorTipTpl>
        <ng-container *ngIf="taxForm.get('zipCode').errors.maxlength">
          Zip Code is too long
        </ng-container>
        <ng-container *ngIf="taxForm.get('zipCode').errors.required">
          Zip Code is required
        </ng-container>
      </ng-template>
    </nz-form-item>
  </nz-col>

    <nz-col nzSm="24" nzMd="12" nzLg="6">
      <nz-form-item [ngClass]="{'has-error': taxForm.get('paymentTerms')?.touched && taxForm.get('paymentTerms')?.errors}">
        <nz-form-label [nzRequired]="isRequired(taxForm, 'paymentTerms')">Payment Terms</nz-form-label>
        <nz-form-control [nzErrorTip]="getError('paymentTerms', 'Payment Terms required')">
          <nz-select formControlName="paymentTerms" id="payment_form_payment_terms"
                     [nzAllowClear] = "userType === 'internal'"
                     nzPlaceHolder="Select payment terms" [nzLoading]="loadingTerms"
                     nzShowArrow="false"
                     [nzSuffixIcon]="selectIcons['paymentTerms'] ? arrowUp : arrowDown"
                     (nzOpenChange)="onChangeSelectIconState($event, 'paymentTerms')"
                     class="readonly-field">
            <nz-option *ngFor="let term of paymentTermsOptions"
                       [nzValue]="term.value"
                       [nzLabel]="term.text"
                       [nzDisabled]="userType === 'external'">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

  <nz-col nzSm="24" nzMd="12" nzLg="6">
    <nz-form-item [ngClass]="{'has-error': taxForm.get('vendor1099')?.touched && taxForm.get('vendor1099')?.errors}">
      <nz-row>
        <nz-form-label [nzRequired]="isRequired(taxForm, 'vendor1099')">1099 Vendor</nz-form-label>
        <span nz-tooltip [nzTooltipTitle]="userType === 'internal' ?
          'Select \'Yes\' if the publisher is located in the US and has a W9 form (tax ID)':
          'Select \'Yes\' if you are located in the US and have a W9 form (tax ID)'">
          <fa-icon icon="question-circle" class="fa-md font-icon-color-grey-cool font-icon-hover-color-primary-main" style="display: inline-flex;"></fa-icon>
        </span>
      </nz-row>

      <nz-form-control [nzErrorTip]="getError('vendor1099', '1099 Vendor is required')">
        <nz-select nzPlaceHolder="1099 Vendor" formControlName="vendor1099" id="payment_form_vendor1099" nzAllowClear
                   nzShowArrow="false"
                   [nzSuffixIcon]="selectIcons['vendor1099'] ? arrowUp : arrowDown"
                   class="readonly-field">
          <nz-option [nzLabel]="'Yes'" [nzValue]="true"></nz-option>
          <nz-option [nzLabel]="'No'" [nzValue]="false"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </nz-col>

  <nz-col nzSm="24" nzMd="12" nzLg="6">
    <nz-form-item
      [ngClass]="{'has-error': taxForm.get('emailPaymentNotifications')?.touched && taxForm.get('emailPaymentNotifications')?.errors}">
      <nz-form-label>
        Email For Payment Notifications
      </nz-form-label>
      <nz-form-control [nzErrorTip]="getError('emailPaymentNotifications', 'Please enter a valid email address')">
        <input nz-input
               formControlName="emailPaymentNotifications"
               id="payment_form_emailPaymentNotifications"
               placeholder="Enter an email for payment notifications"
               class="readonly-field">
      </nz-form-control>
    </nz-form-item>
  </nz-col>

  <nz-col nzSm="24" nzMd="24" nzLg="24" class="margin-top-small">
    <nz-form-item [ngClass]="{'has-error': isTaxFileError()}">
      <nz-form-label [nzRequired]="isTaxFileRequired">Tax Form</nz-form-label>
      <div class="margin-bottom-small small-font" *ngIf="uploadedTaxFiles.length >0 ; else noTaxData">
        <div class="margin-bottom-small" *ngFor="let taxFile of uploadedTaxFiles">
          <ng-template #indicatorTemplate><em nz-icon nzType="loading" style="font-size: 14px;"></em></ng-template>
          <span nz-text nzType="secondary">
            Your tax form file <nz-spin *ngIf="taxFile.taxFileLoading" style="display:inline-block;" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>&nbsp;<a class="underlined-link-button semibold-font" (click)="downloadTaxFile(taxFile.taxFileId)">{{taxFile.taxFileName}}</a> was uploaded at {{taxFile.taxFileCreatedAt | date: 'MM/dd/yyyy'}}
          </span>
        </div>
      </div>
      <ng-container *ngIf="mode === 'edit'">
        <div class="small-font margin-bottom-small">
          <div *ngIf="taxForm.get('country').value === 'US'" class="margin-bottom-small">
            <span nz-text nzType="secondary">
              Please use <a target="_blank" class="underlined-link-button semibold-font" [href]="'https://www.irs.gov/pub/irs-pdf/fw9.pdf'">W9</a> tax form
            </span>
          </div>
          <div *ngIf="taxForm.get('country').value !== 'US'" class="margin-bottom-small">
            <span nz-text nzType="secondary">
              If you are an individual please use <a target="_blank" class="underlined-link-button semibold-font" [href]="'https://www.irs.gov/pub/irs-pdf/fw8ben.pdf'">W8-BEN</a> tax form
            </span>
          </div>
          <div *ngIf="taxForm.get('country').value !== 'US'" class="margin-bottom-small">
            <span nz-text nzType="secondary">
              If you are an entity please use <a target="_blank" class="underlined-link-button semibold-font" [href]="'https://www.irs.gov/pub/irs-pdf/fw8bene.pdf'">W8-BEN-E</a> tax form
            </span>
          </div>
        </div>
      </ng-container>
    </nz-form-item>
  </nz-col>

  <ng-template #noTaxData>
    <nz-col nzSm="24" nzMd="24" nzLg="24" *ngIf="mode === 'read'">
      <div class="margin-bottom-small red">Please note that tax form is missing</div>
    </nz-col>
  </ng-template>
</nz-row>

<ng-template #arrowUp>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-open"></fa-icon>
</ng-template>
<ng-template #arrowDown>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-close"></fa-icon>
</ng-template>
