import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Common } from '../../_common';
import { TermsOfService } from '../../features/signup/register.model';
import { SignupType } from '../../features/signup/_services/signup.config';
import { RoleJava } from '../_models/role';
import { HttpHelperService } from './http-helper.service';

import {
  ApiRequestDataBundle,
  ApprovalQueueEmailDetails,
  AudienceTargetingProviderIdToName,
  AudienceTargetingSegment,
  Audit,
  CloneExchangeDeal,
  CombinedSelectOptions,
  CommonDataModel,
  ContentChannel,
  ContentGenre,
  ContentNetwork,
  Country,
  CtrlUser,
  Deal,
  DealExternalSync,
  DealPartner,
  DomainBundle,
  DomainBundleDownloadParams,
  DspAccount,
  EmailTemplate,
  ExchangeDeal,
  ExchangeDealForecastResult,
  ExchangeRate,
  FeatureToggleKeys,
  Forecast,
  IabAttribute,
  IabBat,
  IabCategoryParent,
  InsightsMetricsRequest,
  InsightsMetricsResponse,
  InventoryPackage,
  JapiQuery,
  JapiTableFilter,
  KeycloakInfo,
  KeycloakToken,
  Last24HoursQps,
  Metro,
  NestAudit,
  OperationsOwner,
  PadAgreement,
  Personnel,
  Placement,
  PlacementStatus,
  PublisherEdit,
  PublisherKvp,
  PublisherKvpKey,
  PublisherKvpValue,
  PublisherMedia,
  PublisherTrafficSource,
  RegionLimitMetadata,
  ReportType,
  ResponseFromServer,
  SentinelResponse,
  SentinelRule,
  SentinelSubscription,
  SentinelSubscriptionResponse,
  State,
  ThinPublisher,
  Timezone,
  TrafficSource,
  UserWithRoles
} from '../_models/models';
import { SentinelRuleGroup } from './alerts.config';
import { CustomURLEncoder } from '../_models/custom-url-encoder';
import { catchError, map } from 'rxjs/operators';
import { NzUploadXHRArgs } from 'ng-zorro-antd/upload';

@Injectable({providedIn: 'root'})
export class ApiService {

  // urls for reporting
  apiExtendedUrl = `${environment.javaInsightsUrl}/extended/metrics`;

  constructor(
    private http: HttpClient,
    private common: Common,
    private httpHelperService: HttpHelperService
  ) {
  }

  generateSpearadToken(): Observable<{ spearadToken: string }> {
    return this.http.get<{ spearadToken: string }>(`${environment.javaApiUrl}/jwt/spearad`).pipe(map(
      token => token),
    catchError((err) => throwError(err)
    ));
  }

  passwordForgot(email: string): Observable<void> {
    return this.http.post<void>(`${environment.javaApiViaKongUrl}/users/password/forgot`, {email: email});
  }

  passwordReset(
    email: string, password: string, passwordConfirmation: string, token: string, signature: string, expiresAt: number
  ): Observable<string> {
    return this.http.post<string>(
      `${environment.javaApiViaKongUrl}/users/password/reset/${token}?expires=${expiresAt}&signature=${signature}`, {
        email: email,
        password: password,
        passwordConfirmation: passwordConfirmation,
      });
  }

  passwordExpiredReset(email: string, oldPassword: string, password: string, passwordConfirmation: string): Observable<string> {
    return this.http.post<string>(
      `${environment.javaApiUrl}/users/password/reset`, {
        email: email,
        oldPassword: oldPassword,
        password: password,
        passwordConfirmation: passwordConfirmation,
      });
  }

  verifyEmailRequest(email: string) {
    return this.http.post<any>(`${environment.javaApiUrl}/users/email/resend`, {email}).pipe();
  }

  verifyEmailSetPassword(payload: any, token: string, expires: string, signature: string) {
    return this.http.post<any>(`${environment.javaApiUrl}/users/email/verify/${token}?expires=${expires}&signature=${signature}`,
      {password: payload.password, passwordConfirmation: payload.passwordConfirmation}).pipe();
  }

  getFeatureToggles(featureToggles: FeatureToggleKeys) {
    return this.http.post(`${environment.javaApiUrl}/toggles/fetch`, featureToggles);
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //             TRAFFIC SOURCES APIs

  getJavaTrafficSources(query = {}): any {
    return this.http.post(`${environment.javaApiUrl}/trafficSource/get`, query);
  }

  getTrafficSources(): Observable<TrafficSource[]> {
    return this.http.get<TrafficSource[]>(`${environment.javaApiUrl}/trafficSource/`);
  }

  getTrafficSourceById(id: number | string): any {
    return this.http.get(`${environment.javaApiUrl}/trafficSource/${id}`);
  }

  createTrafficSource(trafficSource): any {
    return this.http.post(`${environment.javaApiUrl}/trafficSource/`, trafficSource);
  }

  editTrafficSource(trafficSource: any, id: number | string): any {
    return this.http.put(`${environment.javaApiUrl}/trafficSource/${id}`, trafficSource);
  }

  deleteTrafficSource(id: number | string): any {
    return this.http.delete(`${environment.javaApiUrl}/trafficSource/${id}`);
  }

  getSspBasic(search: string = '') {
    return this.http.get(`${environment.javaApiUrl}/trafficSource/ssp`);
  }

  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //             AD SERVER TECH APIs
  getAdServerTechList(): any {
    return this.http.get(`${environment.javaApiUrl}/adServerTech`);
  }

  getAdServerTechById(id: number | string): any {
    return this.http.get(`${environment.javaApiUrl}/adServerTech/${id}`);
  }

  createAdServerTech(adServerTech): any {
    return this.http.post(`${environment.javaApiUrl}/adServerTech/`, adServerTech);
  }

  updateAdServerTech(adServerTech: any, id: number | string): any {
    return this.http.put(`${environment.javaApiUrl}/adServerTech/${id}`, adServerTech);
  }

  deleteAdServerTechById(id: number | string): any {
    return this.http.delete(`${environment.javaApiUrl}/adServerTech/${id}`);
  }

  getTagParamsList(): any {
    return this.http.get(`${environment.javaApiUrl}/adServerTech/tagParams`);
  }

  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //             TERMS OF SERVICE APIs

  getTermsOfService(): Observable<ResponseFromServer<TermsOfService[]>> {
    return this.http.post<ResponseFromServer<TermsOfService[]>>(`${environment.javaApiUrl}/termsofservice/get`, {});
  }

  getTermsOfServiceById(id: number | string): Observable<TermsOfService> {
    return this.http.get<TermsOfService>(`${environment.javaApiUrl}/termsofservice/${id}`);
  }

  getLatestTermsOfService(): Observable<TermsOfService> {
    return this.http.get<TermsOfService>(`${environment.javaApiUrl}/termsofservice/latest`);
  }

  createTermsOfService(termsOfService: TermsOfService): Observable<TermsOfService> {
    return this.http.post<TermsOfService>(`${environment.javaApiUrl}/termsofservice`, termsOfService);
  }


  getDataProcessingAddendum(): Observable<ResponseFromServer<TermsOfService[]>> {
    return this.http.post<ResponseFromServer<TermsOfService[]>>(`${environment.javaApiUrl}/dataProcessingAddendum/get`, {});
  }

  getDataProcessingAddendumById(id: number | string): Observable<TermsOfService> {
    return this.http.get<TermsOfService>(`${environment.javaApiUrl}/dataProcessingAddendum/${id}`);
  }

  getLatestDataProcessingAddendum(): Observable<TermsOfService> {
    return this.http.get<TermsOfService>(`${environment.javaApiUrl}/dataProcessingAddendum/latest`);
  }

  createDataProcessingAddendum(termsOfService: TermsOfService): Observable<TermsOfService> {
    return this.http.post<TermsOfService>(`${environment.javaApiUrl}/dataProcessingAddendum`, termsOfService);
  }


  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //             EMAIL TEMPLATES

  getEmailTemplates(): Observable<ResponseFromServer<EmailTemplate[]>> {
    return this.http.post<ResponseFromServer<EmailTemplate[]>>(`${environment.javaApiUrl}/emailTemplates/get`, {});
  }

  updateEmailTemplate(emailTemplate: EmailTemplate, id: number): Observable<ResponseFromServer<EmailTemplate>> {
    return this.http.put<ResponseFromServer<EmailTemplate>>(`${environment.javaApiUrl}/emailTemplates/${id}`, emailTemplate);
  }

  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //            PUBLISHERS

  createPublisher(payload) {
    return this.http.post(`${environment.javaApiUrl}/publisher`, payload);
  }
  registerPublisher(payload: any, signupType: SignupType) {
    return this.http.post(`${environment.javaApiUrl}/publisher/signup/${signupType}`, payload);
  }

  getPublishers(query: any = {}): Observable<ResponseFromServer<ThinPublisher[]>> {
    return this.http.post<ResponseFromServer<ThinPublisher[]>>(`${environment.javaApiUrl}/publisher/get`, query);
  }

  getPublishersWithIncludes(query: any = {}) {
    return this.http.post(`${environment.javaApiUrl}/publisher/getWithIncludes`, query);
  }

  updatePublisherById(publisher: PublisherEdit): Observable<PublisherEdit> {
    return this.http.put<PublisherEdit>(`${environment.javaApiUrl}/publisher/${publisher.publisherId}`, publisher);
  }

  updatePublisherByIdApprovalQueue(publisher, approvalQueueEmailDetails: ApprovalQueueEmailDetails) {
    return this.http.put(`${environment.javaApiUrl}/publisher/${publisher.publisherId}/approvalQueue`,
      {
        publisher: publisher,
        selectedEmailTemplatePayload: approvalQueueEmailDetails
      }
    );
  }

  resendPublisherZipDetails(publisherId: number): Observable<void> {
    return this.http.put<void>(`${environment.javaApiUrl}/publisher/${publisherId}/zipVendor`, {});
  }

  deletePublisher(publisherId) {
    return this.http.delete(`${environment.javaApiUrl}/publisher/${publisherId}`);
  }

  getPublisherById(publisherId): Observable<PublisherEdit> {
    return this.http.get<PublisherEdit>(`${environment.javaApiUrl}/publisher/${publisherId}`);
  }

  updatePublisherStatusById(publisherId, status) {
    const javaStatus = status.toUpperCase();
    return this.http.put(`${environment.javaApiUrl}/publisher/${publisherId}/status/${javaStatus}`, {});
  }

  updatePublisherInfoById(publisherId, publisherInfo) {
    return this.http.put(`${environment.javaApiUrl}/publisher/${publisherId}/approvalQueueInfo`, publisherInfo);
  }

  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //       PUBLISHER TRAFFIC SOURCES API
  //
  getPublisherTrafficSources(publisherId: number): Observable<PublisherTrafficSource[]> {
    return this.http.get<PublisherTrafficSource[]>(`${environment.javaApiUrl}/publisher/${publisherId}/traffic-source`);
  }

  updatePublisherTrafficSources(publisherId: number, body: FormData): Observable<ResponseFromServer<PublisherTrafficSource[]>> {
    return this.http.post<ResponseFromServer<PublisherTrafficSource[]>>
    (`${environment.javaApiUrl}/publisher/${publisherId}/traffic-source`, body);
  }

  getPublisherTrafficSourcesRegionLimitMetadata(publisherId: number): Observable<RegionLimitMetadata[]> {
    return this.http.get<RegionLimitMetadata[]>(`${environment.javaApiUrl}/publisher/${publisherId}/traffic-source/qps-metadata`);
  }

  getLast24HoursQps(query: InsightsMetricsRequest): Observable<InsightsMetricsResponse<Last24HoursQps>> {
    return this.http.post<InsightsMetricsResponse<Last24HoursQps>>(`${environment.javaInsightsUrl}/qps/last-24-hours`, query);
  }

  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //            INVENTORY PACKAGES APIs

  getInventoryPackages(query): any {
    return this.http.post(`${environment.javaApiUrl}/dealPackage/get`, query ? query : {});
  }

  getInventoryPackageById(query: JapiQuery): Observable<ResponseFromServer<InventoryPackage[]>> {
    return this.http.post<ResponseFromServer<InventoryPackage[]>>(`${environment.javaApiUrl}/dealPackage/get`, query);
  }

  createInventoryPackage(inventoryPackage: FormData): any {
    return this.http.post(`${environment.javaApiUrl}/dealPackage/`, inventoryPackage);
  }

  updateInventoryPackage(currentInventoryPackageId: number, inventoryPackage: FormData): any {
    return this.http.put(`${environment.javaApiUrl}/dealPackage/${currentInventoryPackageId}`, inventoryPackage);
  }

  deleteInventoryPackageById(inventoryPackage: InventoryPackage): any {
    return this.http.delete(`${environment.javaApiUrl}/dealPackage/${inventoryPackage.packageId}`);
  }

  getCountries(query: any = {}): Observable<ResponseFromServer<Country[]>> {
    return this.http.post<ResponseFromServer<Country[]>>(`${environment.javaApiUrl}/location/country/get`, query);
  }

  getRegions(query: JapiQuery = {}): Observable<ResponseFromServer<State[]>> {
    return this.http.post<ResponseFromServer<State[]>>(`${environment.javaApiUrl}/location/region/get`, query);
  }

  getRoles(filters: JapiQuery = {}): Observable<CommonDataModel<RoleJava[]>> {
    return this.httpHelperService.expandPost<RoleJava[]>(`${environment.javaApiUrl}/role/get`, filters);
  }
  createRole(role: RoleJava): Observable<CommonDataModel<RoleJava>> {
    return this.httpHelperService.expandPost<RoleJava>(`${environment.javaApiUrl}/role`, role);
  }
  updateAndRestoreRole(role: RoleJava, id: number): any {
    return this.httpHelperService.expandPost<RoleJava>(`${environment.javaApiUrl}/role/${id}`, role, 'put');
  }
  deleteRole(id: number): any {
    return this.http.delete(`${environment.javaApiUrl}/role/${id}`);
  }
  getMetros(query: any = {}): Observable<ResponseFromServer<Metro[]>> {
    return this.http.post<ResponseFromServer<Metro[]>>(`${environment.javaApiUrl}/location/metro/get`, query);
  }

  getContentAttributes(query: any = {}) {
    return this.http.post(`${environment.javaApiUrl}/dealPackage/get-content-attributes`, query);
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  // == KEY-VALUES PAIRS TARGETING APIs

  getPublisherKvps(publisherId: number): Observable<PublisherKvp[]> {
    return this.http.get<Array<PublisherKvp>>(`${environment.javaApiUrl}/kvps/publishers/${publisherId}`);
  }

  createKvpValues(keyId: number, kvpValues: PublisherKvpValue[]): Observable<PublisherKvpValue[]> {
    return this.http.post<PublisherKvpValue[]>(`${environment.javaApiUrl}/kvps/keys/${keyId}/values`, kvpValues);
  }

  createKvpKey(publisherId: number, kvpKey: PublisherKvpKey): Observable<PublisherKvpKey> {
    return this.http.post<PublisherKvpKey>(`${environment.javaApiUrl}/kvps/publishers/${publisherId}/keys`, kvpKey);
  }

  updateKvpKey(keyId: number, kvpKey: PublisherKvpKey): Observable<PublisherKvpKey> {
    return this.http.put<PublisherKvpKey>(`${environment.javaApiUrl}/kvps/keys/${keyId}`, kvpKey);
  }

  deleteKvpValue(id: number): Observable<PublisherKvpValue> {
    return this.http.delete<PublisherKvpValue>(`${environment.javaApiUrl}/kvps/value/${id}`);
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  // == AUDIENCE TARGETING APIs

  getAudienceTargetingSegments(query: JapiQuery): Observable<ResponseFromServer<AudienceTargetingSegment[]>> {
    return this.http.post<ResponseFromServer<AudienceTargetingSegment[]>>(`${environment.javaApiUrl}/audienceSegments/get`, query);
  }

  getAudienceTargetingProviders(): Observable<AudienceTargetingProviderIdToName> {
    return this.http.get<AudienceTargetingProviderIdToName>(`${environment.javaApiUrl}/audienceSegments/providers`);
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  // == PACKAGES FORECASTS APIs

  getForecastList(query: JapiQuery = {}) {
    return this.http.post<ResponseFromServer<Forecast[]>>(`${environment.javaApiUrl}/deal-packages/forecasts`, query);
  }

  runPubDealForecast(packageId: number, dspAccountName: string) {
    const queryParams = `?dspAccountName=${dspAccountName}`;
    return this.http.get(`${environment.javaApiUrl}/deal-packages/${packageId}/forecast${queryParams}`);
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  // == PLACEMENTS APIs

  getPlacements(query: JapiQuery = {}): Observable<ResponseFromServer<Placement[]>> {
    return this.http.post<ResponseFromServer<Placement[]>>(`${environment.javaApiUrl}/placement/get`, query);
  }

  getContentGenres(): Observable<ResponseFromServer<ContentGenre[]>> {
    return this.http.get<ResponseFromServer<ContentGenre[]>>(`${environment.javaApiUrl}/placement/content-genre-list`);
  }

  getPublisherTrafficSourceById(query, publisherId) {
    return this.http.post<ResponseFromServer<PublisherTrafficSource[]>>(
      `${environment.javaApiUrl}/publisher/${publisherId}/traffic-source/options`, query
    );
  }

  getIabCategories(): Observable<ResponseFromServer<IabCategoryParent[]>>{
    return this.http.get<ResponseFromServer<IabCategoryParent[]>>(`${environment.javaApiUrl}/iabCategory/tree`);
  }

  getIabBannerAdTypes(): Observable<ResponseFromServer<IabBat[]>> {
    return this.http.get<ResponseFromServer<IabBat[]>>(`${environment.javaApiUrl}/iabCategory/bat`);
  }

  getIabCreativeAttributes(): Observable<ResponseFromServer<IabAttribute[]>> {
    return this.http.get<ResponseFromServer<IabAttribute[]>>(`${environment.javaApiUrl}/iabCategory/creativeAttribute`);
  }

  updatePlacement(placement: any, placementId: number): any {
    return this.http.put(`${environment.javaApiUrl}/placement/${placementId}`, placement);
  }

  updatePlacementStatus(placementId: number, placementStatus: PlacementStatus): Observable<ResponseFromServer<Placement>> {
    return this.http.patch<ResponseFromServer<Placement>>(`${environment.javaApiUrl}/placement/${placementId}/status`, placementStatus);
  }

  createPlacement(placement: any): any {
    return this.http.post(`${environment.javaApiUrl}/placement/`, placement);
  }

  getBulkUploadStatus(id: number): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'no-retry-request-with-error': 'true',
        'no-error-notification': 'true',
      })
    };
    return this.http.get(`${environment.javaApiUrl}/bulk_upload/${id}`, {headers: httpOptions.headers});
  }

  // == DOMAIN BUNDLES
  getDomainBundles(parentType: string, query: any = {}): Observable<ResponseFromServer<DomainBundle[]>> {
    const url = parentType === 'INVENTORY-PACKAGE' ? 'domain-bundle-package/get' : 'domain-bundle/get';
    return this.http.post<ResponseFromServer<DomainBundle[]>>(`${environment.javaApiUrl}/${url}`, query);
  }

  checkDomainBundleDuplicateStatus(placementId: number) {
    return this.http.get(`${environment.javaApiUrl}/bulk_upload/PLACEMENT/${placementId}`);
  }

  duplicateDomainBundles(old_placement_id: number | string, new_placement_id: number | string): any {
    return this.http.post(`${environment.javaApiUrl}/placement/domain_copy/${old_placement_id}/${new_placement_id}`, null);
  }

  getDeviceTypes(query: any = {}) {
    return this.http.get(`${environment.javaApiUrl}/optionsList/?enumsKeys=DeviceType`);
  }

  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //            DEALS APIs

  getDeals(query): any {
    return this.http.post(`${environment.javaApiUrl}/deal/get`, query ? query : {});
  }

  getDealById(query): Observable<ResponseFromServer<Deal[]>> {
    return this.http.post<ResponseFromServer<Deal[]>>(`${environment.javaApiUrl}/deal/get`, query);
  }

  createDeal(deal: Deal): Observable<Deal> {
    return this.http.post<Deal>(`${environment.javaApiUrl}/deal/`, deal);
  }

  updateDeal(deal: Deal): Observable<Deal> {
    return this.http.put<Deal>(`${environment.javaApiUrl}/deal/${deal.pubDealId}`, deal);
  }

  uploadMarketplaceLogo(uploadXHRArgs: NzUploadXHRArgs, dealId: string): Observable<{ logoPath: string }> {
    const formData = new FormData();
    formData.append('docType', 'GOOGLE_MARKETPLACE_LOGO');
    formData.append('file', uploadXHRArgs.postFile as Blob);

    return this.http.post<{ logoPath: string }>(`${environment.javaApiUrl}/deal/${dealId}/marketplace-logo`, formData);
  }

  deleteDealById(deal: Deal): any {
    return this.http.delete(`${environment.javaApiUrl}/deal/${deal.pubDealId}`);
  }

  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //            DSP ACCOUNTS APIs

  getDspAccounts(query): Observable<ResponseFromServer<DspAccount[]>> {
    return this.http.post<ResponseFromServer<DspAccount[]>>(`${environment.javaApiUrl}/dspAccount/get`, query ? query : {});
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //            GLOBAL SEARCH APIs

  getGlobalSearch(searchVal, pageSize = 50, pageNumber = 1) {
    return this.http.get(`${environment.javaApiUrl}/global-search?text=${searchVal}&pageSize=${pageSize}&pageNumber=${pageNumber}`);
  }
  //  *********************************************************************************************************
  //  *********************************************************************************************************
  // // JAVA TOKEN

  getKeycloakToken(email: string, password: string): Observable<KeycloakToken> {
    const httpBody = new HttpParams({encoder: new CustomURLEncoder() })
      .set('grant_type', 'password')
      .set('username', email)
      .set('password', password)
      .set('scope', 'openid')
      .set('client_id', 'fusion-ctrl');
    return this.http.post<KeycloakToken>(`${environment.authServer}/token`, httpBody);
  }

  getKeycloakUserInfo(accessToken: string): Observable<KeycloakInfo> {
    const headers = new HttpHeaders({'Authorization': `Bearer ${accessToken}`});
    return this.http.post<KeycloakInfo>(`${environment.authServer}/userinfo`, {}, {headers});
  }

  refreshAuth(refreshToken: string): Observable<KeycloakToken> {
    const httpBody = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken)
      .set('client_id', 'fusion-ctrl');
    return this.http.post<KeycloakToken>(`${environment.authServer}/token`, httpBody);
  }

  javaLogout(refreshToken: string): Observable<void> {
    const httpBody = new HttpParams()
      .set('refresh_token', refreshToken)
      .set('client_id', 'fusion-ctrl');
    return this.http.post<void>(`${environment.authServer}/logout`, httpBody);
  }

  captchaValidate(token: string) {
    const urlQuery = `token=${token}`;
    return this.http.get(`${environment.javaApiUrl}/captcha/validate?${urlQuery}`, {});
  }


  //  *********************************************************************************************************
  //  *********************************************************************************************************
  // // CREATING USERS WITH JAVA API - MULTIPLE USERS
  createNewUser(payload: any) {
    return this.http.post(`${environment.javaApiUrl}/users/`, payload);
  }

  getUsers(payload: any): any {
    return this.http.post(`${environment.javaApiUrl}/users/get`, payload);
  }

  getUserById(id: number): any {
    return this.http.get(`${environment.javaApiUrl}/users/${id}`);
  }

  updateUserCredential(payload: any, userId: number, userType: string): Observable<CtrlUser> {
    if (userType === 'INTERNAL') {
      return this.http.put<CtrlUser>(`${environment.javaApiUrl}/users/${userId}`, payload);
    } else if (userType === 'EXTERNAL') {
      return this.http.put<CtrlUser>(`${environment.javaApiUrl}/users/me`, payload.user);
    }
  }

  deleteUser(userId: number): any {
    return this.http.delete(`${environment.javaApiUrl}/users/${userId}`);
  }

  getThinUsers(query: any, publisherId = null): any {
    return this.http.post(`${environment.javaApiUrl}/users/getThinUser`, query);
  }

  getThinUsersExternal(query: any, publisherId: number): any {
    return this.http.post(`${environment.javaApiUrl}/users/getThinUserExternal/${publisherId}`, query);
  }

  getSignupDetails(publisherId: number): any {
    return this.http.get(`${environment.javaApiUrl}/users/getFirstSignupUser/${publisherId}`);
  }

  generateApiKey(userId: number): any {
    return this.http.post(`${environment.javaApiUrl}/users/${userId}/api-key`, {});
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  // // CREATING USERS WITH JAVA API - WITH HTTP HELPER EXPAND FUNCTIONS

  getUsersWithRoles(payload: JapiQuery): Observable<CommonDataModel<UserWithRoles[]>> {
    return this.httpHelperService.expandPost<UserWithRoles[]>(`${environment.javaApiUrl}/users/getUserWithRoles`, payload);
  }

  getMyUsersWithRoles(): Observable<UserWithRoles> {
    return this.http.get<UserWithRoles>(`${environment.javaApiUrl}/users/getMyUserWithRoles`);
  }

  createUserWithRoles(user: UserWithRoles): any {
    return this.httpHelperService.expandPost<UserWithRoles[]>(`${environment.javaApiUrl}/users/`, user);
  }

  updateUserWithRoles(user: any, id: number | string): any {
    return this.httpHelperService.expandPost<UserWithRoles[]>(`${environment.javaApiUrl}/users/${id}`, user, 'put');
  }

  deleteUserWithRoles(id: number): any {
    return this.http.delete(`${environment.javaApiUrl}/users/${id}`);
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  // // AUDIT
  getAuditData(query: JapiQuery, endpoint: string, fromDate: string | 0, toDate: string | 0): Observable<CommonDataModel<Audit[]>> {
    return this.httpHelperService.expandPost<Audit[]>(`${environment.javaApiUrl}/${endpoint}?fromDate=${fromDate}&toDate=${toDate}`, query);
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  // // REPORTS
  getReportMetrics(reportType: ReportType, data: ApiRequestDataBundle, isExport: boolean = false) {
    let reportPath: string;
    switch (reportType) {
      case ReportType.SUPPLY:
      case ReportType.PUBLISHER:
        reportPath = 'supply/summary/metrics';
        break;
      case ReportType.DETAILED:
        reportPath = 'extended/metrics/detailedSupply';
        break;
      case ReportType.DEMAND:
        reportPath = 'extended/metrics/demand';
        break;
      case ReportType.DEALS:
        reportPath = 'deals/metrics';
        break;
      case ReportType.QPS:
        reportPath = 'qps/metrics';
        break;
      case ReportType.CURATED_MARKETPLACE:
        reportPath = 'exchange/curated-marketplace/metrics';
        break;
      default:
        return;
    }
    const url = `${environment.javaInsightsUrl}/${reportPath}${this.common.toQueryParamsString(data.queryParams)}`;
    if (isExport) {
      const httpOptions = {
        headers: new HttpHeaders({
          // 'no-retry-request-with-error':  'true',
          'no-error-notification': 'true',
          // 'no-error-message': 'true'
        })
      };
      return this.http.post<any>(url, data.payload, {headers: httpOptions.headers});
    } else {
      return this.http.post<any>(url, data.payload);
    }
  }

  getReportTotals(reportType: ReportType, data: ApiRequestDataBundle) {
    let reportPath: string;
    switch (reportType) {
      case ReportType.SUPPLY:
      case ReportType.PUBLISHER:
        reportPath = 'supply/summary/totals';
        break;
      case ReportType.DEALS:
        reportPath = 'deals/totals';
        break;
      case ReportType.DETAILED:
        reportPath = 'extended/totals/detailedSupply';
        break;
      case ReportType.DEMAND:
        reportPath = 'extended/totals/demand';
        break;
      case ReportType.QPS:
        reportPath = 'qps/totals';
        break;
      case ReportType.CURATED_MARKETPLACE:
        reportPath = 'exchange/curated-marketplace/totals';
        break;
      default:
        return;
    }
    const url = `${environment.javaInsightsUrl}/${reportPath}${this.common.toQueryParamsString(data.queryParams)}`;
    return this.http.post<any>(url, data.payload);
  }

  getDetailedDemandReport(reportType: ReportType, isNoRetry: boolean, quickDate: string, startDate: number, endDate: number, params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'no-retry-request': 'true',
        'no-error-message': 'true'
      })
    };
    const url =
      `${this.apiExtendedUrl}/${reportType}?${quickDate ? 'dateRange=' + quickDate + '&' : ''}startDate=${startDate}&endDate=${endDate}`;
    if (isNoRetry) {
      // return this.http.post<any>(url, params, {headers: httpOptions.headers, responseType: 'blob' as 'json'});
      return this.http.post<any>(url, params, {headers: httpOptions.headers});
    } else {
      return this.http.post<any>(url, params);
    }
  }

  getInsightsFileFromS3(fileName: string) {
    const url = `${environment.javaInsightsUrl}/s3/fileURL${this.common.toQueryParamsString({fileName})}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'no-retry-request-with-error': 'true',
      }),
    };
    return this.http.get<any>(url, {headers: httpOptions.headers});
  }

  getFileFromUrl(url: string) {
    return this.http.get(url);
  }

  getBizdevOwners(query: JapiQuery = {
    sorting: {
      sortDirection: 'ASC',
      sortingField: 'name'
    }
  }): Observable<ResponseFromServer<OperationsOwner[]>> {
    const filterActive: JapiTableFilter = {fieldName: 'status', operation: 'EQUALS', value: 'ACTIVE'};
    if (query.filter?.filters) {
      const filterStatusExist = query.filter.filters.some(f => f.fieldName === 'status');
      if (!filterStatusExist) {
        query.filter.filters.push(filterActive);
      }
    } else {
      query.filter = {filters: [filterActive]};
    }
    return this.http.post<ResponseFromServer<OperationsOwner[]>>(`${environment.javaApiUrl}/supply/publisher-bizdev-owner/get`, query);
  }

  getOpsOwners(query: any = {
    sorting: {
      sortDirection: 'ASC',
      sortingField: 'name'
    }
  }) {
    return this.http.post(`${environment.javaApiUrl}/pubOpOwner/get`, query);
  }

  getTop10ForExternalDashboard(queryParams: Record<string, string>): any {
    const queryParamsString = this.common.toQueryParamsString(queryParams);
    return this.http.get(`${environment.javaInsightsUrl}/dashboard/top10Entities${queryParamsString}`);
  }

  getMinuteViewTop(queryParams: Record<string, string>): any {
    const queryParamsString = this.common.toQueryParamsString(queryParams);
    return this.http.get(`${environment.javaInsightsUrl}/dashboard/minuteViewTop${queryParamsString}`);
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //  == audit

  getAudit(query, fromDate, toDate): any {
    return this.http.post(`${environment.javaApiUrl}/audit/get?startDate=${fromDate}&endDate=${toDate}`, query);
  }

  getComponents(query): any {
    return this.http.post(`${environment.javaApiUrl}/deal/get`, query ? query : {});
  }
  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //  OPTIONS LIST - enums list from JAVA

  getOptionsLists(enumKey?: string): Observable<{ [index: string]: { [key: string]: string } }> {
    if (enumKey) {
      return this.http.get<{ [index: string]: { [key: string]: string } }>(`${environment.javaApiUrl}/optionsList/?enumsKeys=${enumKey}`);
    } else {
      return this.http.get<{ [index: string]: { [key: string]: string } }>(`${environment.javaApiUrl}/optionsList/`);
    }
  }

  getTimezonesList(): Observable<ResponseFromServer<Timezone[]>> {
    return this.http.post<ResponseFromServer<Timezone[]>>(`${environment.javaApiUrl}/timezone/get`, {});
  }

  getCurrenciesList() {
    return this.http.post(`${environment.javaApiUrl}/currency/get`, {});
  }

  getExchangeRate(date?: string): Observable<ExchangeRate[]> {
    if (date){
      return this.http.get<ExchangeRate[]>(`${environment.javaInsightsUrl}/exchange-rates?date=${date}`);
    } else {
      return this.http.get<ExchangeRate[]>(`${environment.javaInsightsUrl}/exchange-rates`);
    }
  }

  downloadBlockedAdvertisers(placementId: number) {
    const httpOptions = {
      responseType: 'text' as 'json',
      headers: new HttpHeaders({
        'use-server-filename': 'true',
        'download-file': 'true',
      }),
    };
    return this.http.get(`${environment.javaApiUrl}/placement/${placementId}/blocked-advertisers/file`, httpOptions);
  }
  downloadDomainBundles(params: DomainBundleDownloadParams): Observable<unknown> {
    const httpOptions = {
      responseType: 'text' as 'json',
      headers: new HttpHeaders({
        'use-server-filename': 'true',
        'download-file': 'true',
      }),
    };

    let url: string;
    let queryParams: any = {};

    switch (params.parentType) {
      case 'PLACEMENT':
        url = 'domain-bundle/placement/file';
        queryParams = { 'placement_id': params.id, 'placement_name': params.name };
        break;

      case 'INVENTORY-PACKAGE':
        url = 'domain-bundle-package/package/file';
        queryParams = { 'package_id': params.id, 'package_name': params.name };
        break;

      case 'TRAFFIC-SOURCE':
        url = 'domain-bundle/pubTs/file';
        queryParams = { 'pub_ts_id': params.id, 'ts_id': params.tsId, 'pub_ts_name': params.name };
        break;

      case 'RX-DEAL':
        url = `segment-map/${params.segmentId}/csv-download`;
        queryParams = null; // No query params needed for this case
        break;

      case 'PAD_AGREEMENT':
        url = `pad-agreement-adomain/${params.id}/csv-download`;
        queryParams = null; // No query params needed for this case
        break;

      default:
        throw new Error('Invalid parent type for domain bundle download');
    }

    const apiUrl = ['RX-DEAL', 'PAD_AGREEMENT'].includes(params.parentType)
      ? `${environment.exchangeDealsUrl}/${url}`
      : `${environment.javaApiUrl}/${url}`;

    return this.http.get(apiUrl, { ...httpOptions, ...(queryParams ? { params: queryParams } : {}) });

  }

  getPaymentHistory(accountingId: number, startDate: string, endDate: string) {
    return this.httpHelperService.expandGet(
      `${environment.javaApiUrl}/publisher/netsuite/vendors/${accountingId}/payments?startDate=${startDate}&endDate=${endDate}`
    );
  }

  getContentNetworks(publisherId: number): Observable<ContentNetwork[]> {
    return this.http.get<ContentNetwork[]>(`${environment.javaApiUrl}/content/network/${publisherId}`);
  }

  getContentChannels(publisherId: number): Observable<ContentChannel[]> {
    return this.http.get<ContentChannel[]>(`${environment.javaApiUrl}/content/channel/${publisherId}`);
  }

  getDealExternalSyncId(dealId: number): Observable<DealExternalSync> {
    return this.http.get<DealExternalSync>(`${environment.javaApiUrl}/deal/${dealId}/sync/external-id`);
  }

  syncDealExternal(dealId: number): Observable<DealExternalSync> {
    return this.http.get<DealExternalSync>(`${environment.javaApiUrl}/deal/${dealId}/sync`);
  }

  retrieveDealExternalSyncStatus(dealId: number): Observable<DealExternalSync> {
    return this.http.get<DealExternalSync>(`${environment.javaApiUrl}/deal/${dealId}/sync/retrieve-status`);
  }

  getDealExternalSyncLogs(dealId: number, query: JapiQuery = {}): Observable<CommonDataModel<DealExternalSync[]>> {
    return this.httpHelperService.expandPost<DealExternalSync[]>
    (`${environment.javaApiUrl}/deal/${dealId}/sync/logs`, query);
  }

  getDealExchangeExternalSyncId(dealId: number): Observable<ResponseFromServer<DealExternalSync>> {
    return this.http.get<ResponseFromServer<DealExternalSync>>(`${environment.exchangeDealsUrl}/deals/${dealId}/sync/external-id`);
  }

  syncDealExchangeExternal(dealId: number): Observable<ResponseFromServer<DealExternalSync>> {
    return this.http.get<ResponseFromServer<DealExternalSync>>(`${environment.exchangeDealsUrl}/deals/${dealId}/sync`);
  }

  retrieveDealExchangeExternalSyncStatus(dealId: number): Observable<ResponseFromServer<DealExternalSync>> {
    return this.http.get<ResponseFromServer<DealExternalSync>>(`${environment.exchangeDealsUrl}/deals/${dealId}/sync/retrieve-status`);
  }

  getDealExchangeExternalSyncLogs(dealId: number, filters: string): Observable<CommonDataModel<DealExternalSync[]>> {
    return this.httpHelperService.expandNestGet<DealExternalSync[]>
    (`${environment.exchangeDealsUrl}/deals/${dealId}/sync/logs?${filters}`);
  }

  getExchangeDeals(filters: string): Observable<CommonDataModel<ExchangeDeal[]>> {
    return this.httpHelperService.expandNestGet<ExchangeDeal[]>(`${environment.exchangeDealsUrl}/deals?${filters}`);
  }

  getExchangeDealCampaigns(filters: string): Observable<ResponseFromServer<string[]>> {
    return this.http.get<ResponseFromServer<string[]>>(`${environment.exchangeDealsUrl}/deals/exchange/campaigns?${filters}`);
  }

  createExchangePad(padAgreement: PadAgreement): Observable<ResponseFromServer<PadAgreement>> {
    return this.http.post<ResponseFromServer<PadAgreement>>(`${environment.exchangeDealsUrl}/pads`, padAgreement);
  }

  getExchangePadById(paId: string): Observable<ResponseFromServer<PadAgreement>> {
    return this.http.get<ResponseFromServer<PadAgreement>>(`${environment.exchangeDealsUrl}/pads/${paId}`);
  }

  getExchangePads(filters: string): Observable<CommonDataModel<PadAgreement[]>> {
    return this.httpHelperService.expandNestGet<PadAgreement[]>(`${environment.exchangeDealsUrl}/pads?${filters}`);
  }

  updateExchangePad(paId: string, padAgreement: PadAgreement): Observable<ResponseFromServer<PadAgreement>> {
    return this.http.put<ResponseFromServer<PadAgreement>>(`${environment.exchangeDealsUrl}/pads/${paId}`, padAgreement);
  }

  getRxSegments(filters: string): Observable<CommonDataModel<AudienceTargetingSegment[]>> {
    return this.httpHelperService.expandNestGet<AudienceTargetingSegment[]>(`${environment.exchangeDealsUrl}/segments?${filters}`);
  }

  getSegmentMedia(segmentId: number, filters: string): Observable<CommonDataModel<string[]>> {
    return this.httpHelperService.expandNestGet<string[]>(
      `${environment.exchangeDealsUrl}/segments/media-domain/${segmentId}?${filters}`
    );
  }

  getPadAgreementDomains(padId: number, filters: string): Observable<CommonDataModel<string[]>> {
    return this.httpHelperService.expandNestGet<string[]>(
      `${environment.exchangeDealsUrl}/pad-agreement-adomain/domains/${padId}?${filters}`
    );
  }

  getPublisherSegmentMedia(payload: PublisherMedia): Observable<CommonDataModel<string[]>> {
    return this.httpHelperService.expandNestPost<string[]>(`${environment.exchangeDealsUrl}/segments/publishers-media`, payload);
  }

  getExchangePersonnel(): Observable<ResponseFromServer<Personnel[]>> {
    return this.http.get<ResponseFromServer<Personnel[]>>(`${environment.exchangeDealsUrl}/deal-personnels`);
  }

  getExternalDealId(): Observable<ResponseFromServer<string>> {
    return this.http.get<ResponseFromServer<string>>(`${environment.exchangeDealsUrl}/deals/exchange/external-deal-id`);
  }

  getCombinedSelectOptions(): Observable<ResponseFromServer<CombinedSelectOptions>> {
    const addNexxenPartnerAccount = (dealPartners: DealPartner[]): void => {
      const nexxenPartner = {} as DealPartner;
      nexxenPartner.id = 0;
      nexxenPartner.displayName = 'Nexxen';
      dealPartners.unshift(nexxenPartner);
    };
    return this.http.get<ResponseFromServer<CombinedSelectOptions>>(`${environment.exchangeDealsUrl}/combined-select-options`).pipe(
      map(res => {
        if (res?.data?.dealPartners) {
          addNexxenPartnerAccount(res.data.dealPartners);
        }
        return res;
      })
    );
  }

  createExchangeDeal(currentDeal: ExchangeDeal): Observable<ResponseFromServer<ExchangeDeal>> {
    return this.http.post<ResponseFromServer<ExchangeDeal>>(`${environment.exchangeDealsUrl}/deals`, currentDeal);
  }

  cloneExchangeDeal(
    currentDeal: CloneExchangeDeal,
    dealId: number,
  ): Observable<ResponseFromServer<ExchangeDeal>> {
    const body = currentDeal;
    return this.http.post<ResponseFromServer<ExchangeDeal>>(
      `${environment.exchangeDealsUrl}/deals/${dealId}/clone-deal`,
      body
    );
  }

  updateExchangeDeal(dealId: number, currentDeal: ExchangeDeal): Observable<ResponseFromServer<ExchangeDeal>> {
    return this.http.put<ResponseFromServer<ExchangeDeal>>(`${environment.exchangeDealsUrl}/deals/${dealId}`, currentDeal);
  }

  deleteExchangeDeal(dealId: number): Observable<ResponseFromServer<void>> {
    return this.http.delete<ResponseFromServer<void>>(`${environment.exchangeDealsUrl}/deals/${dealId}`);
  }

  getCurrentExchangeDeal(dealId: number): Observable<ResponseFromServer<ExchangeDeal>> {
    return this.http.get<ResponseFromServer<ExchangeDeal>>(`${environment.exchangeDealsUrl}/deals/${dealId}`);
  }

  getCurrentPartnerForecastDeal(partnerForecastDealId: number): Observable<ResponseFromServer<ExchangeDeal>> {
    return this.http.get<ResponseFromServer<ExchangeDeal>>(`${environment.exchangeDealsUrl}/deals/${partnerForecastDealId}/forecast`);
  }

  getExchangeDealForecastResults(dealId: number): Observable<ResponseFromServer<ExchangeDealForecastResult[]>> {
    return this.http.get<ResponseFromServer<ExchangeDealForecastResult[]>>(`${environment.exchangeDealsUrl}/deal-forecast/${dealId}/results`);
  }

  runExchangeDealForecast(dealId: number): Observable<any> {
    return this.http.get<any>(`${environment.exchangeDealsUrl}/deal-forecast/${dealId}/run`);
  }

  getExchangeDealForecastStatus(dealId: number, forecastId: number): Observable<ResponseFromServer<ExchangeDealForecastResult>> {
    return this.http.get<ResponseFromServer<ExchangeDealForecastResult>>(
      `${environment.exchangeDealsUrl}/deal-forecast/${dealId}/status/${forecastId}`
    );
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //  NEST AUDIT

  getNestAudit(filters: string): Observable<CommonDataModel<NestAudit[]>>  {
    return this.httpHelperService.expandNestGet(`${environment.exchangeDealsUrl}/audit?${filters}`);
  }

  //  *********************************************************************************************************
  //  *********************************************************************************************************
  //  SENTINEL
  subscribeAlert(payload: SentinelSubscription): Observable<SentinelResponse<SentinelSubscriptionResponse>> {
    return this.http.post<SentinelResponse<SentinelSubscriptionResponse>>(`${environment.sentinelApi}/subscriptions`, payload);
  }

  updateSubscription(subscriptionId: number, payload: SentinelSubscription): Observable<SentinelResponse<SentinelSubscriptionResponse>> {
    return this.http.patch<SentinelResponse<SentinelSubscriptionResponse>>(
      `${environment.sentinelApi}/subscriptions/${subscriptionId}`,
      payload
    );
  }

  unsubscribeAlert(subscriptionId: number): Observable<SentinelResponse<SentinelSubscriptionResponse>> {
    return this.http.delete<SentinelResponse<SentinelSubscriptionResponse>>(`${environment.sentinelApi}/subscriptions/${subscriptionId}`);
  }

  subscribeManyAlerts(payload: SentinelSubscription[]): Observable<SentinelResponse<SentinelSubscriptionResponse[]>> {
    return this.http.post<SentinelResponse<SentinelSubscriptionResponse[]>>(`${environment.sentinelApi}/subscriptions/bulk`, payload);
  }

  unsubscribeManyAlerts(subscriptionIds: number[]): Observable<SentinelResponse<SentinelSubscriptionResponse[]>> {
    return this.http.delete<SentinelResponse<SentinelSubscriptionResponse[]>>(`${environment.sentinelApi}/subscriptions/bulk`,
      { body: subscriptionIds});
  }

  getSentinelSubscriptions(userId: number): Observable<SentinelResponse<SentinelSubscriptionResponse[]>> {
    return this.http.get<SentinelResponse<SentinelSubscriptionResponse[]>>(`${environment.sentinelApi}/subscriptions/users/${userId}`);
  }

  getSentinelRules(): Observable<SentinelResponse<SentinelRule[]>> {
    return this.http.get<SentinelResponse<SentinelRule[]>>(`${environment.sentinelApi}/rules`);
  }

  getSentinelRuleById(ruleId: number): Observable<SentinelResponse<SentinelRule>> {
    return this.http.get<SentinelResponse<SentinelRule>>(`${environment.sentinelApi}/rules/${ruleId}`);
  }

  getSentinelRulesByUserType(userType: SentinelRuleGroup): Observable<SentinelResponse<SentinelRule[]>> {
    return this.http.get<SentinelResponse<SentinelRule[]>>(`${environment.sentinelApi}/rules/groups/${userType}`);
  }

  getZendeskToken(): Observable<{zendeskToken: string}> {
    return this.http.get<{zendeskToken: string}>(`${environment.javaApiUrl}/jwt/zendesk`);
  }


}
